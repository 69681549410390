/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply a dark background color to the body */
body {
  background-color: #333;
  color: #fff;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Style the login container */
.login-container {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  min-width: 300px;
  text-align: center;
}

/* Style the login form */
.login-form {
  display: flex;
  flex-direction: column;
}

.login-form input[type="text"],
.login-form input[type="password"] {
  padding: 10px;
  margin: 5px 0;
  border: none;
  border-radius: 3px;
}

.login-form .submit-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 3px;
}

.login-form .submit-btn:disabled {
  opacity: 0.7;
}

/* Style the form heading */
.form-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.address {
  font-family: monospace;
  color: #fff;
}

.metamask-connect-button {
    background-color: #F6851B;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 15px 30px;
    font-size: 22px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.metamask-connect-button:hover {
    background-color: #E57400;
}

.metamask-connect-button:active {
    background-color: #D36300;
}


.loader {
  margin: auto;
}

.error {
  text-align: left;
  font-size: 0.9rem;
  color: #e00;
  padding: 0.2em 0;
}

.title {
  text-align: center;
  padding: 0.2em 0;
  margin: 3em auto;
}
